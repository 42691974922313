@media print {

    .sidebarHide {
        display: none;
    }

    .navbarHide {
        display: none;
    }

    .btnHide {
        display: none;
    }
}