@media print {

    .sidebarHide {
        display: none;
    }

    .navbarHide {
        display: none;
    }

    .btnHide {
        display: none;
    }
}

.container-box {
    display: flex;
    justify-content: space-between;
    width: 350px;
}

.element {
    padding: 1px;
    vertical-align: bottom;
    height: 80px; /* Set a height for the container */
    display: flex; /* Or use display: inline-block; on the container */
    align-items: flex-end;
}